/*

  File: main.js
  Kind: Component
  Description: Frame to hold main page content

*/

import React from 'react';


// markup
const Main = ({children}) => {
  return (
    <main>
      {/* <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"> */}
      <main className="mx-auto max-w-7xl mt-2 md:mt-8 px-4 sm:px-6 lg:px-8 ">
        {children}
      </main>

      {/* </div> */}
    </main>
  )
}

export default Main
