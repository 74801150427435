/*

  File: socialIcon.js
  Kind: Component
  Description: Display social icon, with link as specified in props

*/

import React from 'react';

import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../util/fontawesome.js';

export default function SocialIcon(data) {
  const iconColor = 'text-saturn hover:text-neptune';

  const ParsedLink = ({ children }) => {
    if (data.link.substring(0, 1) === '/') {
      //is internal link
      return <Link to={data.link}>{children}</Link>;
    } else {
      //is external link
      return (
        <a href={data.link} target="_blank"  rel="noreferrer noopener">
          {children}
        </a>
      );
    }
  };

  if (data.stack) {
    return (
      <div key={data.id} className="text-xl">
        <ParsedLink>
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={data.stack} className={iconColor} />
            <FontAwesomeIcon icon={data.icon} inverse transform="shrink-5" />
          </span>
        </ParsedLink>
      </div>
    );
  } else {
    return (
      <div key={data.id} className="text-xl">
        <ParsedLink>
          <FontAwesomeIcon icon={data.icon} fixedWidth className={iconColor} />
        </ParsedLink>
      </div>
    );
  }
}
