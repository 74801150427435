/*

  File: pageBanner.js
  Kind: Component
  Description: Page banner with title and subtitle that are read from the site config

*/

import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

export default function PageBanner() {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `
  );
  //console.log(data)
  return (
    <header className="bg-white">
      <div className="max-w-7xl mx-auto pt-6 pb-4 px-4 sm:px-6 lg:px-8">
        <div className="font-code text-saturn-700 text-xs">project:</div>
        <Link
          to="/"
          className="mb-4 text-5xl sm:text-6xl font-title font-normal text-saturn hover:text-neptune"
          style={{ lineHeight: 'unset' }}
        >
          {data.site.siteMetadata.title}
        </Link>
        <div className="font-code text-saturn-700 text-xs mb-2">description:</div>
        <h2 className="text-sm font-title font-medium leading-tight text-jupiter-100 w-4/5">
          {data.site.siteMetadata.subtitle}
        </h2>
      </div>
    </header>
  );
}
