import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, pageTitle, metaImage, pathName }) {
  const { site, defaultImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
        defaultImage: file(relativePath: { eq: "social/social001_lapislr.jpg" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;

  const image =
    metaImage && metaImage.resize && metaImage.resize.src
      ? {
          src: site.siteMetadata.siteUrl + metaImage.resize.src,
          width: metaImage.resize.width,
          height: metaImage.resize.height,
        }
      : {
          src: site.siteMetadata.siteUrl + defaultImage.childImageSharp.resize.src,
          width: defaultImage.childImageSharp.resize.width,
          height: defaultImage.childImageSharp.resize.height,
        };

  // console.log("DEFAULT_IMAGE:", defaultImage.childImageSharp);
  // console.log("META_IMAGE:", metaImage);
  // console.log("IMAGE:", image);

  const canonical = [site.siteMetadata.siteUrl, pathName].join('');

  const title = pageTitle ? [pageTitle, site.siteMetadata.title].join(' | ') : site.siteMetadata.title;

  // console.log('SEO', pathName, canonical, title);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords.join(','),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          image
            ? [
                {
                  property: 'og:image',
                  content: image.src,
                },
                {
                  property: 'og:image:width',
                  content: image.width,
                },
                {
                  property: 'og:image:height',
                  content: image.height,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ]
        )
        .concat(meta)}
    >
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  pageTitle: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
};

export default SEO;
