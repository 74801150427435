/*

  File: pageBanner.js
  Kind: Component
  Description: Page banner with title and subtitle that are read from the site config

*/

import React from 'react';

//Components
import SEO from '../components/seo.jsx';
import MacroNavigation from '../components/macroNavigation.js';
import PageBanner from '../components/pageBanner.js';
import Navigation from '../components/navigation.js';
import Main from '../components/main.js';
import Footer from '../components/footer.js';

// markup
const Layout = ({ pageTitle, location, children, metaImage }) => {

  const pathName = (location && location.pathname) || '';
  //console.log(location);

  return (
    <div>
      <SEO pageTitle={pageTitle} pathName={pathName} metaImage={metaImage}/>
      <MacroNavigation />
      <PageBanner />
      <Navigation />
      <Main>{children}</Main>
      <Footer />
    </div>
  );
};

export default Layout;
