/*

  File: navigation.js
  Kind: Component
  Description: Top level banner for navigation back to the main with.lasers site - visibility is controlled by siteMetadata.navigation.macro

*/

import React from 'react'
import { Link } from 'gatsby'
import * as styles from '../../styles/navigation.module.css'

const Item = (data) => {
  
  const isPartiallyActive = ({
    isCurrent,
    isPartiallyCurrent
  }) => {
    return (data.id === "HOME" && isCurrent) || (data.id !== "HOME" && isPartiallyCurrent)
      ? { className: styles.active }
      : { className: styles.normal }
  }

  return (
      <Link to={data.link} getProps={isPartiallyActive}>
        {data.title}
      </Link>
  )
}

export default Item
