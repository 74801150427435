/*

  File: navigation/menuItem.js
  Kind: Component
  Description: Dropdown menu item for mobile / responsive additional menu items

*/

import React from 'react'
import { Link } from 'gatsby'
import * as styles from '../../styles/navigation.module.css'

const MenuItem = (data) => {
  
  return (
      <Link to={data.link} className={styles.menu_normal} activeClassName={styles.menu_active}>
        {data.title}
      </Link>
  )
}

export default MenuItem