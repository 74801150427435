/*

  File: footer.js
  Kind: Component
  Description: Top level banner for navigation back to the main with.laserts site - visibility is controlled by siteMetadata.navigation.macro

*/

import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import SocialIcon from '../components/socialIcon.js';

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              id
              icon
              link
            }
            copyright {
              date
              owner
            }
          }
        }
        siteBuildMetadata {
          buildTime(fromNow: true)
        }
      }
    `
  );
  return (
    <footer className="bg-white">
      <div className="grid grid-cols-4 grid-rows-2 max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 border-saturn-800 border-t">
        <div className="font-code text-saturn-700 text-xs col-span-3">social & developer:</div>
        <div className="font-code text-saturn-700 text-xs text-right">copyright:</div>
        <div className="flex flex-row space-x-4 col-span-3">
          {data.site.siteMetadata.social.map(({ id, icon, link }, index) => (
            <SocialIcon key={id} id={id} icon={icon} link={link} stack={null} />
          ))}
        </div>
        <div className="text-right">
          <Link to="/colophon" className="font-serif text-saturn font-regular text-sm hover:text-neptune">
            &copy; {data.site.siteMetadata.copyright.owner} {data.site.siteMetadata.copyright.date}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
