/*

  File: navigation.js
  Kind: Component
  Description: Top level banner for navigation back to the main with.laserts site - visibility is controlled by siteMetadata.navigation.macro

*/

import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Item from '../components/navigation/item.js';
import MenuItem from '../components/navigation/menuItem.js';
import '../util/fontawesome.js';

const Navigation = () => {
  const [state, setState] = useState({
    menuOpen: false,
  });

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            navigation {
              fixed {
                id
                link
                title
                active
              }
              standard {
                id
                link
                title
                active
              }
            }
          }
        }
      }
    `
  );

  const onClick = () => {
    setState({
      ...state,
      menuOpen: !state.menuOpen,
    });
    // console.log('menu',state.menuOpen)
  };

  return (
    <div className={`max-w-7xl mx-auto -m-l-4 mb-8 md:mb-16 px-4 sm:px-6 lg:px-8`}>
      <div className="font-code text-saturn-700 text-xs mb-2">project navigation:</div>
      <div className="flex items-center justify-between h-4">
        <div className="flex justify-start">
          <div className="flex items-baseline space-x-4">
            {data.site.siteMetadata.navigation.fixed
              .filter(({ active }) => active)
              .map(({ id, link, title }, index) => (
                <Item key={id} id={id} title={title} link={link} />
              ))}
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-baseline space-x-4">
              {data.site.siteMetadata.navigation.standard
                .filter(({ active }) => active)
                .map(({ id, link, title }, index) => (
                  <Item key={id} id={id} title={title} link={link} />
                ))}
            </div>
          </div>
        </div>
        <div className="-mr-2 flex md:hidden">
          <button
            className="inline-flex items-center justify-center p-2 text-saturn hover:text-neptune focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            onClick={onClick}
          >
            <span className="sr-only">Open main menu</span>
            <span className={`transform-gpu duration-500 block h-6 w-6 ${state.menuOpen ? '-rotate-90' : 'rotate-0'}`}>
              &lt;
            </span>
          </button>
        </div>
      </div>
      <div className={state.menuOpen ? `` : `hidden md:hidden`}>
        <div className="pt-4 space-y-1">
          {data.site.siteMetadata.navigation.standard
            .filter(({ active }) => active)
            .map(({ id, link, title }) => (
              <MenuItem key={id} id={id} title={title} link={link} />
            ))}
        </div>
        <hr className="border-saturn-700 mt-2 mr-48" />
      </div>
    </div>
  );
};

export default Navigation;
